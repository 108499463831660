import styled from 'styled-components';

// export const StyledFooter = styled.footer`
//   position: absolute;
//   width: 100vw;
//   box-shadow: 5px 5px 512px 32px ${({ color }) => color ? color : 'white'};
//  `

export const StyledFooter = styled.footer`
  position: absolute;
  width: 100vw;
  height: 1vh;
  background-color: ${({ color }) => color ? color : 'white'};
  bottom: 0vh;
  `