import styled from 'styled-components'

export const StyledTable = styled.table`
  position: absolute;
  border-collapse: collapse;
  top: 5vh;
  left: 20vh;
  border-spacing: 0;
  table-layout: fixed;
  width: 80vw;
  height: 40vh;
  background-color: rgba(28, 28, 28, 0.55);
  font-size: ${({ size }) => size ? `${size}px` : 'inherit'};
  opacity: ${ ({ isVisible }) => isVisible ? 1 : 0 };
  font-weight: normal;
`