import React from "react";
import styled from "styled-components";
import {QuestionStyles} from "./question.styles";
import {StyledButton} from "../styled-button/Button.styles";

const QuestionDisplay = (props) => {
  return (
    <QuestionStyles {...props}/>
  );
};

export default QuestionDisplay;