import styled from 'styled-components'

export const StyledTd = styled.td`
  color: ${({ color }) => color ? 'grey' : 'inherit'};
  border-color: ${({ color }) => color ? 'grey' : 'inherit'};
  border-style: solid;
  border-width: 2px;
  font-family: Arial, sans-serif;
  overflow: hidden;
  padding: 10px 5px;
  text-align: center;
  vertical-align: top;
  word-break: normal;
  font-size: inherit;
  font-weight: bold;
`
