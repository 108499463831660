import './main.css';
import {StyledButton} from "./styled-button/Button.styles";
import React, {useEffect, useState} from "react";
import Strong from "./styled-strong/Strong";
import Footer from "./footer-styled/Footer";
import Td from "./td-styled/Td";
import QuestionDisplay from "./question-styled/question";

import {aus_first, aus_second, aus_third, aus_fourth, aus_fifth, aus_topics} from "../data/australia";
import {usa_first, usa_second, usa_third, usa_fourth, usa_fifth, usa_topics} from "../data/usa";
import {gb_first, gb_second, gb_third, gb_fourth, gb_fifth, gb_topics} from "../data/great-britain";
import {irl_first, irl_second, irl_third, irl_fourth, irl_fifth, irl_topics} from "../data/ireland";
import Table from "./table-styled/Table";

function Main() {
  const [currentPage, setCurrentPage] = useState('Australia');
  const [currentColor, setCurrentColor] = useState('orangered');
  const [selected, setSelected] = useState([]);
  const [currentFontSize, setCurrentFontSize] = useState(28);
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [questionVisible, setQuestionVisibility] = useState(false);
  const [questionBackground, setQuestionBackground] = useState('rgba(255, 69, 0, 0.5)');
  const [tableVisibility, setTableVisibility] = useState(true);
  const [currentDefault, setCurrentDefault] = useState(['5-4', '5-5'])

  const [current1, setCurrent1] = useState(undefined);
  const [current2, setCurrent2] = useState(undefined);
  const [current3, setCurrent3] = useState(undefined);
  const [current4, setCurrent4] = useState(undefined);
  const [current5, setCurrent5] = useState(undefined);

  const [topic, setTopic] = useState([undefined, 'Life', 'History', 'Geography', 'People', 'Language']);

  const setBlock = (id, message) => {
    if (message) {
      setCurrentQuestion(message);
      setQuestionVisibility(true);
      setSelected(selected => [...selected, id])
      return true;
    } else {
      return false;
    }
  }

  let selected_copy = [...selected]

  useEffect(() => {

    if (currentQuestion) {
      switch (questionVisible) {
        case true:
          setTableVisibility(false)
          break;
        case false:
          setTableVisibility(true)
          break;
      }
    }

    switch(currentPage) {
      case 'Australia':

        setCurrent1(aus_first);
        setCurrent2(aus_second);
        setCurrent3(aus_third);
        setCurrent4(aus_fourth);
        setCurrent5(aus_fifth);
        setTopic(aus_topics);

        setCurrentColor('orangered');
        setQuestionBackground('rgba(255, 69, 0, 0.5)')
        setCurrentDefault(['5-5', '5-4', '5-3', '5-2']);
        setSelected(selected => [...selected, ...currentDefault]);
        break;
      case 'USA':
        setCurrent1(usa_first);
        setCurrent2(usa_second);
        setCurrent3(usa_third);
        setCurrent4(usa_fourth);
        setCurrent5(usa_fifth);
        setTopic(usa_topics);

        setCurrentColor('#d32f2f')
        setQuestionBackground('rgba(211, 47, 47, 0.5)')
        setCurrentDefault(['5-4', '5-3']);
        setSelected(selected => [...selected, ...currentDefault])
        break;
      case 'Great Britain':
        setCurrent1(gb_first);
        setCurrent2(gb_second);
        setCurrent3(gb_third);
        setCurrent4(gb_fourth);
        setCurrent5(gb_fifth);
        setTopic(gb_topics);

        setCurrentColor('dodgerblue')
        setQuestionBackground('rgba(30, 114, 255, 0.5)')
        setCurrentDefault([]);
        setSelected(selected => [...selected, ...currentDefault])
        break;
      case 'Ireland':
        setCurrent1(irl_first);
        setCurrent2(irl_second);
        setCurrent3(irl_third);
        setCurrent4(irl_fourth);
        setCurrent5(irl_fifth);
        setTopic(irl_topics);

        setCurrentColor('limegreen')
        setQuestionBackground('rgba(0, 191, 165, 0.5)')
        setCurrentDefault(['5-2', '5-3', '5-4', '5-5']);
        setSelected(selected => [...selected, ...currentDefault])
        break;
    }
  }, [currentQuestion, tableVisibility, questionVisible, currentPage, currentColor, current1])

  return(
  <>
    <header className={"header"}>
      <div className={"button-container"}>
        <StyledButton onClick={() => {
          setCurrentPage('Australia');
        }} borderColor={`${currentPage === 'Australia' ? 'orangered' : 'white'}`}  color={"#333845"} width={"75px"} height={"50px"} className={"header-button"} >
          Australia
        </StyledButton>
        <StyledButton onClick={() => {
          setCurrentPage('USA');
          setSelected(currentDefault);
        }} borderColor={`${currentPage === 'USA' ? '#d32f2f' : 'white'}`} color={"#333845"} width={"75px"} height={"50px"} className={"header-button"} >
          USA
        </StyledButton>
        <StyledButton onClick={() => {
          setCurrentPage('Great Britain');
          setSelected(currentDefault);
        }} borderColor={`${currentPage === 'Great Britain' ? 'dodgerblue' : 'white'}`} color={"#333845"} width={"75px"} height={"50px"} className={"header-button"}  >
          Great Britain
        </StyledButton>
        <StyledButton onClick={() => {
          setCurrentPage('Ireland');
          setSelected(currentDefault);
        }} borderColor={`${currentPage === 'Ireland' ? 'limegreen' : 'white'}`} color={"#333845"} width={"75px"} height={"50px"} className={"header-button"}  >
          Ireland
        </StyledButton>
        <StyledButton onClick={() => {
          setSelected(currentDefault)
          setCurrentQuestion('')
        } } borderColor={'red'} color={"#333845"} style={{ borderRadius: '100px', marginLeft: '5vw' }} width={"50px"} height={"50px"} className={"header-button"}  >
          Clear
        </StyledButton>
        <div>
          <StyledButton onClick={() => setCurrentFontSize(currentFontSize + 2)} color={"#333845"} width={"25px"} height={"25px"} className={"header-button"} style={{
            marginBottom: '.5vh'
          }}  >
            +
          </StyledButton>
          <StyledButton onClick={() => setCurrentFontSize(currentFontSize - 2)} color={"#333845"} width={"25px"} height={"25px"} className={"header-button"}  >
            -
          </StyledButton>
        </div>
      </div>
      <div className={'header-title'}>
        Va Banque | <Strong color={currentColor}>{currentPage}</Strong>
      </div>
    </header>
    <div className="App">
      <QuestionDisplay isVisible={questionVisible} backgroundColor={questionBackground}>
        <h1 style={{ borderBottom: `5px solid ${currentColor}`, width: '20vw', textAlign: 'left'}}>Question</h1>
        <p style={{ fontSize: currentFontSize, textAlign: 'left' }}>{`${currentQuestion}`}</p>
        <StyledButton onClick={() => setQuestionVisibility(false)} style={{ borderRadius: '20px', marginRight: 'auto', color: 'black' }} width={"75px"} height={"50px"}>
          Dismiss
        </StyledButton>
      </QuestionDisplay>
      <div className={"tab-container"}>
        <Table size={currentFontSize} isVisible={tableVisibility}>
          <colgroup>
            <col />
          </colgroup>
          <thead>
          <tr>
            <Td>
              {topic[1]}
            </Td>
            <Td>
              {topic[2]}
            </Td>
            <Td>
              {topic[3]}
            </Td>
            <Td>
              {topic[4]}
            </Td>
            <Td>
              {topic[5]}
            </Td>
          </tr>
          </thead>
          <tbody>
          <tr style={{
            color: 'greenyellow'
          }}>
            <Td id={"1-1"} onClick={() => setBlock('1-1', current1[1])} color={selected_copy.find(element => element === '1-1')}>
              100
            </Td>
            <Td id={"2-1"} onClick={() => setBlock('2-1', current2[1])} color={selected_copy.find(element => element === '2-1')}>
              100
            </Td>
            <Td id={"3-1"} onClick={() => setBlock('3-1', current3[1])} color={selected_copy.find(element => element === '3-1')}>
              100
            </Td>
            <Td id={"4-1"} onClick={() => setBlock('4-1', current4[1])} color={selected_copy.find(element => element === '4-1')}>
              100
            </Td>
            <Td id={"5-1"} onClick={() => setBlock('5-1', current5[1])} color={selected_copy.find(element => element === '5-1')}>
              100
            </Td>
          </tr>
          <tr style={{
            color: 'yellow'
          }}>
            <Td id={"1-2"} onClick={() => setBlock('1-2', current1[2])} color={selected_copy.find(element => element === '1-2')}>
              200
            </Td>
            <Td id={"2-2"} onClick={() => setBlock('2-2', current2[2])} color={selected_copy.find(element => element === '2-2')}>
              200
            </Td>
            <Td id={"3-2"} onClick={() => setBlock('3-2', current3[2])} color={selected_copy.find(element => element === '3-2')}>
              200
            </Td>
            <Td id={"4-2"} onClick={() => setBlock('4-2', current4[2])} color={selected_copy.find(element => element === '4-2')}>
              200
            </Td>
            <Td id={"5-2"} onClick={() => setBlock('5-2', current5[2])} color={selected_copy.find(element => element === '5-2')}>
              200
            </Td>
          </tr>
          <tr style={{
            color: 'gold'
          }}>
            <Td id={"1-3"} onClick={() => setBlock('1-3', current1[3])} color={selected_copy.find(element => element === '1-3')}>
              300
            </Td>
            <Td id={"2-3"} onClick={() => setBlock('2-3', current2[3])} color={selected_copy.find(element => element === '2-3')}>
              300
            </Td>
            <Td id={"3-3"} onClick={() => setBlock('3-3', current3[3])} color={selected_copy.find(element => element === '3-3')}>
              300
            </Td>
            <Td id={"4-3"} onClick={() => setBlock('4-3', current4[3])} color={selected_copy.find(element => element === '4-3')}>
              300
            </Td>
            <Td id={"5-3"} onClick={() => setBlock('5-3', current5[3])} color={selected_copy.find(element => element === '5-3')}>
              300
            </Td>
          </tr>
          <tr style={{
            color: 'darkorange'
          }}>
            <Td id={"1-4"} onClick={() => setBlock('1-4', current1[4])} color={selected_copy.find(element => element === '1-4')}>
              400
            </Td>
            <Td id={"2-4"} onClick={() => setBlock('2-4', current2[4])} color={selected_copy.find(element => element === '2-4')}>
              400
            </Td>
            <Td id={"3-4"} onClick={() => setBlock('3-4', current3[4])} color={selected_copy.find(element => element === '3-4')}>
              400
            </Td>
            <Td id={"4-4"} onClick={() => setBlock('4-4', current4[4])} color={selected_copy.find(element => element === '4-4')}>
              400
            </Td>
            <Td id={"5-4"} onClick={() => setBlock('5-4', current5[4])} color={selected_copy.find(element => element === '5-4')}>
              400
            </Td>
          </tr>
          <tr style={{
            color: 'rgb(241, 47, 47)'
          }}>
            <Td id={"1-5"} onClick={() => setBlock('1-5', current1[5])} color={selected_copy.find(element => element === '1-5')}>
              500
            </Td>
            <Td id={"2-5"} onClick={() => setBlock('2-5', current2[5])} color={selected_copy.find(element => element === '2-5')}>
              500
            </Td>
            <Td id={"3-5"} onClick={() => setBlock('3-5', current3[5])} color={selected_copy.find(element => element === '3-5')}>
              500
            </Td>
            <Td id={"4-5"} onClick={() => setBlock('4-5', current4[5])} color={selected_copy.find(element => element === '4-5')}>
              500
            </Td>
            <Td id={"5-5"} onClick={() => setBlock('5-5', current5[5])} color={selected_copy.find(element => element === '5-5')}>
              500
            </Td>
          </tr>
          </tbody>
        </Table>
      </div>
    </div>
    <Footer color={currentColor} />
  </>
  );
}
export default Main;
