import styled from "styled-components";

export const StyledButton = styled.button`
  width: ${({ width }) => width ? width : '22px'};
  height: ${({ height }) => height ? height : '22px'};
  margin: 0 10px;
  border-radius: 8px;
  border: 2px solid ${({ borderColor }) => borderColor ? borderColor : 'white'};
  background-color: ${({ color }) => color ? color : 'white'};
  display: flex;
  //box-shadow: 0 0 ${({ borderColor }) => borderColor ? '6px' : 0} ${({ borderColor }) => borderColor ? '1px' : 0} ${({ borderColor }) => borderColor ? borderColor : 'white'};
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: ${({ borderColor }) => borderColor ? borderColor : 'white'};
`;