import styled from 'styled-components';

export const QuestionStyles = styled.div`
  position: absolute;
  top: 15vh;
  width: 90vw;
  height: 80vh;
  //background-color: ${ ({ backgroundColor }) => backgroundColor };
  opacity: ${ ({ isVisible }) => isVisible ? 1 : 0.5 };
  z-index: ${ ({ isVisible }) => isVisible ? 100 : -500 };
  //border: white 2px solid;
`;
